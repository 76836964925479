import React from "react";
import Content from '../views/Leasing/Main';

export default ({location}) => (
  <Content location={location} seo={{
    lang: 'da-DK',
    title: "En smart connected car løsning til leasingselskaber",
    description: 'En løsning til leasingselskaber som en del af connected car tjenester vil forenkle køretøjsrelaterede opgaver og hjælpe med at øge kundetilfredshed.',
    meta: [{
      name: 'keywords',
      content: 'Leasingselskaber'
    }]
  }}/>
);